import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { delay } from 'rxjs/operators';
import { Globals } from '../globals';
import { Customer } from '../types';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {
  customers;
  page = 2;
  maxPages: Number;

  constructor(
    private globals: Globals 
  ) {
    this.customers = this.globals.customers;
    this.maxPages = Math.ceil(this.customers / 15);
  }

  processCustomers(data) {
    let customersKeys = Object.keys(data);
    let customersValues = Object.values(data);
    let customers = [];
    customersKeys.forEach((element, index) => {
      customers[index] = {
        name: customersValues[index]['n'],
        active: customersValues[index]['a'],
        id: customersKeys[index],
      }
    });
    this.customers = customers.sort((a, b) => a.name < b.name ? -1 : (a.name > b.name ? 1 : 0));
  }

  filterCustomers(customers: Customer[], text: string) {
    return customers.filter(customer => {
      return customer.name.toLowerCase().indexOf(text) !== -1 ||
        customer.name.toLowerCase().indexOf(text) !== -1;
    });
  }

  getCustomers(page?: number, size?: number, active = true): Customer[] {
    let customers = [];
    let customersTemp = this.customers.filter(customer => {
      return customer.active == active
    });
    if (page && size) {
      customers = customersTemp.slice((page - 1) * size, ((page - 1) * size) + size);
    } else {
      customers = customersTemp;
    }
    return customers;
  }

  getCustomersAsync(page?: number, size?: number, active = true, timeout = 2000): Observable<Customer[]> {
    return new Observable<Customer[]>(observer => {
      observer.next(this.getCustomers(page, size, active));
      observer.complete();
    }).pipe(delay(timeout));
  }
}
