import { Injectable } from '@angular/core';

@Injectable()
export class Globals {
  token: string;
  user: any;
  roles = {};
  goals : any;
  customers: any;
  globalGoals = {};
  visitsFiltered : Boolean = false;
}
