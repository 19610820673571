import { CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClientModule, HttpClient} from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AngularFireModule } from '@angular/fire';
import { IonicStorageModule } from '@ionic/storage-angular';
import { Globals } from './globals';
import { DateRangeCalendarComponent } from './components/date-range-calendar/date-range-calendar.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { registerLocaleData } from '@angular/common';
import es from '@angular/common/locales/es';
import { Camera } from '@ionic-native/camera/ngx';

registerLocaleData(es);
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

const config = {
  apiKey: "AIzaSyD0R4zWeZ_VLtlFvV6gDljfncF2AbGK3Wc",
  authDomain: "boumo-7bb3a.firebaseapp.com",
  projectId: "boumo-7bb3a",
  storageBucket: "boumo-7bb3a.appspot.com",
  messagingSenderId: "211434416905",
  appId: "1:211434416905:web:08a5b6706657f1f6b9c1d8",
  measurementId: "G-38QE3QS4Z9"
};

@NgModule({
  declarations: [AppComponent, DateRangeCalendarComponent],
  entryComponents: [],
  imports: [
    BrowserModule, 
    IonicModule.forRoot(), 
    AppRoutingModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    AngularFireModule.initializeApp(config),
    IonicStorageModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    Globals,
    Storage,
    Geolocation,
    { provide: LOCALE_ID, useValue: 'es-PA' },
    Camera
  ],
  exports: [
    TranslateModule
  ],
  bootstrap: [AppComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class AppModule {}
