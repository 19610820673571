import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { map } from 'rxjs/operators';
import { Storage } from '@ionic/storage';
import firebase from 'firebase/app';
import 'firebase/storage';
@Injectable({
  providedIn: 'root'
})
export class FirebaseService {
  query: any;
  dev = false;

  constructor(private db: AngularFirestore, private storage: Storage) { }

  prepareVisitQuery(type, data) {
    let refCollection = this.db.collection(data.collection);
    if (type =='visits') {
      this.query = refCollection.ref.where(data.field, '==', data.value)
        .orderBy(data.orderField, 'desc').limit(data.limit);
      if (data.startAfter) {
        this.query = this.query.startAfter(data.startAfter);
      }
    } else if (type == 'visits-admin') {
      this.query = refCollection.ref;
      if (data.field) {
        this.query = this.query.where(data.field, 'in', data.valuesIn);  
      }
      if (data.placed && data.placed.value !== null) {
        this.query = this.query.where('placed', '==', data.placed.value);  
      }
      if (data.inPlace && data.inPlace.value !== null) {
        console.log(data.inPlace.value);
        this.query = this.query.where('inPlace', '==', data.inPlace.value);  
      }
      this.query = this.query.orderBy(data.orderField, 'desc').startAfter(data.startAfter).limit(data.limit)
      if (data.startAfter) {
        this.query = this.query.startAfter(data.startAfter).limit(data.limit)
      }
    }
    return this.processPromiseQuery(this.query, data);
  }

  prepareQuery(data) {
    let refCollection = this.db.collection(data.collection);
    this.query = refCollection.ref;
    data.whereConditions.forEach(element => {
      this.query = this.query.where(element.field, '==', element.value);  
    });

    this.query = this.query.orderBy(data.orderBy.field, data.orderBy.order).limit(data.limit)
    return this.processPromiseQuery(this.query, data);
  }

  processPromiseQuery(request, dataQuery = null) {
    return new Promise<Array<any>>((resolve, reject) => {
      request.get().then(
        result => {
          let processed = [];
          result.forEach(a => {
            let data = a.data() as {};
            let id = a.id;
            if (dataQuery && dataQuery.extras) {
              if (dataQuery.extras.ids.includes(data[dataQuery.extras.field])) {
                processed.push({ id, ...data });
              }
            } else {
              processed.push({ id, ...data });
            }
          });
          resolve(processed as []);
        }
      );
    });
  }

  processSubscribeQuery(request: firebase.firestore.Query) {
    let observer = request.onSnapshot(function(snapshot) {
      snapshot.docChanges().forEach(function(change) {          
      });
    });
  }

  processQuery(query: AngularFirestoreCollection) {
    return query.snapshotChanges().pipe(map(
      result => {
        return result.map(a => {
          const data = a.payload.doc.data() as {};
          const id = a.payload.doc.id;
          return { id, ...data };
        });
      }
    ));
  }

  getCollection(name) {
    return new Promise((resolve, reject) => {
      if (name === '') {
        reject(false);
      }
      const query = this.db.collection(name);
      query.get().subscribe(
        result => {
          let data = [];
          result.forEach(doc => {
            data.push({ id: doc.id, ...doc.data() as {} });
          });
          resolve(data);
        }
      );
    });
  }

  obsCollection(name, orderBy = null, order = 'DESC') {
    const collection = this.db.collection(name);
    return collection.snapshotChanges().pipe(map(
      result => {
        return result.map(a => {
          const data = a.payload.doc.data() as {};
          const id = a.payload.doc.id;
          return { id, ...data };
        });
      }
    ));
  }

  obsCollectionOrderBy(name, orderBy = null, order, limit) {
    const collection = this.db.collection(name, ref => 
      ref.orderBy(orderBy, order).limit(limit));
    return collection.snapshotChanges().pipe(map(
      result => {
        return result.map(a => {
          const data = a.payload.doc.data() as {};
          const id = a.payload.doc.id;
          return { id, ...data };
        });
      }
    ));
  }

  obsCollectionWhereOrderBy(name, field, value, orderBy = null, order, limit, active = false) {
    if (active) {
      let collection = this.db.collection(name, ref => 
        ref.where(field, '==', value).where('active', '==', active).orderBy(orderBy, order).limit(limit));
        return collection.snapshotChanges().pipe(map(
          result => {
            return result.map(a => {
              const data = a.payload.doc.data() as {};
              const id = a.payload.doc.id;
              return { id, ...data };
            });
          }
        ));
    } else {
      let collection = this.db.collection(name, ref => 
        ref.where(field, '==', value).orderBy(orderBy, order).limit(limit));
        return collection.snapshotChanges().pipe(map(
          result => {
            return result.map(a => {
              const data = a.payload.doc.data() as {};
              const id = a.payload.doc.id;
              return { id, ...data };
            });
          }
        ));
    }
  }

  obsCollectionWhereIn(name, field, values) {
    const collection = this.db.collection(name, ref => 
      ref.where(field, 'in', values));
    return collection.snapshotChanges().pipe(map(
      result => {
        return result.map(a => {
          const data = a.payload.doc.data() as {};
          const id = a.payload.doc.id;
          return { id, ...data };
        });
      }
    ));
  }

  getCollectionBy(collection, field, value) {
    const query = this.db.collection(collection, ref => ref.where(field, '==', value));
    return query.snapshotChanges().pipe(map(
      result => {

      }
    ));
  }

  searchCollectionBy(collection, field, value, orderBy = null, order = null, limit = null) {
    const query = this.db.collection(collection, ref => 
      ref.where(field, '==', value));
    return query.snapshotChanges().pipe(map(
      result => {
        return result.map(a => {
          const data = a.payload.doc.data() as {};
          const id = a.payload.doc.id;
          return { id, ...data };
        });
      }
    ));
  }

  obsCollectionBy(collection, field = null, value = null, sortField = null, sortOrder = 'desc', limit = null, start = null) {
    if (!sortField) {
      const query = this.db.collection(collection, ref => ref.where(field, '==', value));
      return query.snapshotChanges().pipe(map(
        result => {
          return result.map(a => {
            const data = a.payload.doc.data() as {};
            const id = a.payload.doc.id;
            return { id, ...data };
          });
        }
      ));
    } else {
      const query = this.db.collection(collection, ref => ref.where(field, '==', value).orderBy(sortField, 'desc'));
      return query.snapshotChanges().pipe(map(
        result => {
          return result.map(a => {
            const data = a.payload.doc.data() as {};
            const id = a.payload.doc.id;
            return { id, ...data };
          });
        }
      ));
    }

  }

  getDocument(collection, doc) {
    return new Promise((resolve, reject) => {
      if (doc === '') {
        reject(false);
      }
      const query = this.db.collection(collection).doc(doc);
      query.get().subscribe(
        result => {
          const id = result.id;
          const data = result.data() as {};
          if (data) {
            resolve({id, ...data});
          } else {
            resolve(null);
          }
        }
      );
    });
  }

  deleteDocument(collection, doc) {
    return new Promise((resolve, reject) => {
      if (doc === '') {
        reject(false);
      }
      const query = this.db.collection(collection).doc(doc);
      return resolve(query.delete());
    });
  }

  obsDocument(collection, doc, includesId = true) {
    const query = this.db.collection(collection).doc(doc);
    return query.snapshotChanges().pipe(map(
      result => {
        const data = result.payload.data() as {};
        if (data) {
          const id = result.payload.id;
          if (includesId === true) {
            return { id, ...data };
          } else {
            return data;
          }
        }
        else {
          return null;
        }
      }
    ));
  }

  deleteFieldDocument(collection, doc, field) {
    return new Promise((resolve, reject) => {
      const query = this.db.collection(collection).doc(doc);
      query.update({
        [field]: firebase.firestore.FieldValue.delete()
      }).then(res => resolve(true)).catch(error => reject(false))
    });
  }

  updateDocument(collection, doc = null, data = null) {
    if (this.dev == true) {
      collection =  collection + '_dev';
    }
    return new Promise((resolve, reject) => {
      if (doc) {
        const query = this.db.collection(collection).doc(doc);
        query.get().subscribe(
          result => {
            if (result.exists) {
              query.update(data).then(
                res => resolve(res)
              );
            }
            else {
              query.set(data).then(
                res => resolve(res)
              );
            }
          }
        );
      }
      else {
        const query = this.db.collection(collection);
        query.add(data).then(
          res => resolve(res)
        );
      }
    });
  }

  setDocument(collection, doc = null, data = null) {
    return new Promise((resolve, reject) => {
      if (doc) {
        const query = this.db.collection(collection).doc(doc);
        query.get().subscribe(
          result => {
            query.set(data).then(
              res => resolve(res)
            );
          }
        );
      }
      else {
        const query = this.db.collection(collection);
        query.add(data).then(
          res => resolve(res)
        );
      }
    });

  }

  incrementVisit(customer, user: String, counts, date = null) {
    user = user.replace('.', '_');
    let d = date ? date : new Date();

    let dateString = d.getDate()  + "-" + (d.getMonth()+1) + "-" + d.getFullYear().toString().slice(2);
    let monthString = (d.getMonth()+1) + "" + d.getFullYear().toString().slice(2);
    let docs = [
      [monthString + 'global', 'global', 'stats'],
      [monthString + customer, 'customer', 'statsCustomer'],
      [monthString + user, 'user', 'statsUser']
    ];
    for (let doc of docs) {
      let child = doc[1] == 'customer' ? user : doc[1] == 'user' ? customer : null;
      let query = this.db.collection(doc[2]).doc(doc[0]);
      query.get().subscribe(
        result => {
          if (result.exists) {
            const data = result.data() as {};
            let v1 = data[dateString] ? data[dateString].visits + counts.visits : counts.visits;
            let v2 = data[dateString] ? data[dateString].placed + counts.placed : counts.placed;
            let v3 = data[dateString] ? data[dateString].processed + counts.processed : counts.processed;
            
            let vChild = child ? {
              [child] : {
                visits : data[child] && data[child].visits ? data[child].visits + counts.visits : counts.visits,
                placed : data[child] && data[child].placed ? data[child].placed + counts.placed : counts.placed,
                processed : data[child] && data[child].processed ? data[child].processed + counts.processed: counts.processed,
                [dateString] : {
                  visits : data[child] && data[child][dateString] && data[child][dateString].visits ? data[child][dateString].visits + counts.visits : counts.visits,
                  placed : data[child] && data[child][dateString] && data[child][dateString].placed ? data[child][dateString].placed + counts.placed : counts.placed,
                  processed : data[child] && data[child][dateString] && data[child][dateString].processed ? data[child][dateString].processed + counts.processed: counts.processed,                      
                }
              }
            } : null;
            let vToUp =  {
              [dateString] : {
                visits: v1,
                placed: v2,
                processed: v3
              }
            }

            let updateParams = Object.assign({
              date: monthString,
              visits: firebase.firestore.FieldValue.increment(counts.visits),
              placed: firebase.firestore.FieldValue.increment(counts.placed),
              processed: firebase.firestore.FieldValue.increment(counts.processed)
            }, vToUp, vChild);

            query.update(updateParams).then(
              res => {
              }
            );
          } else {
            let vChild = child ? {
              [child] : {
                visits : counts.visits,
                placed : counts.placed,
                processed : counts.processed,
                [dateString] : {
                  visits : counts.visits,
                  placed : counts.placed,
                  processed : counts.processed,                      
                }
              }
            } : null;
            query.set({
              date: monthString,
              visits: firebase.firestore.FieldValue.increment(counts.visits),
              placed: firebase.firestore.FieldValue.increment(counts.placed),
              processed: firebase.firestore.FieldValue.increment(counts.processed),
              [dateString] : {
                visits: firebase.firestore.FieldValue.increment(counts.visits),
                placed: firebase.firestore.FieldValue.increment(counts.placed),
                processed: firebase.firestore.FieldValue.increment(counts.processed),
              }, ...vChild
            }).then(
              res => {
              }
            );
          }
        }
      );
    }

    let docsMap = [
      [monthString, 'mapVisits']
    ];
    for (let doc of docsMap) {
      let query = this.db.collection(doc[1]).doc(doc[0]);
      query.get().subscribe(
        result => {
          if (result.exists) {
            const data = result.data() as {};
            let dateC = [];
            if (data[dateString] && data[dateString]['c']) {
              dateC = data[dateString]['c'];
              dateC.push(customer);
            } else {
              dateC = [customer];
            }

            let vToUp =  {
              [dateString] : {
                c: dateC
              }
            }

            let updateParams = Object.assign({
              date: monthString,
              c: firebase.firestore.FieldValue.arrayUnion(customer),
            }, vToUp);
            query.update(updateParams).then(
              res => {
              }
            );
          } else {
            query.set({
              date: monthString,
              c: [customer],

              [dateString] : {
                c: [customer]
              }
            }).then(
              res => {
              }
            );
          }
        }
      );
    }
  }
}
