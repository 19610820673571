import { Component } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import { TranslateService } from '@ngx-translate/core';
import { Globals } from './globals';
import { CustomerService } from './services/customer.service';
import { FirebaseService } from './services/firebase.service';
import { Platform } from '@ionic/angular';
declare var UXCam:any;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  constructor(
    private translate: TranslateService,
    private storage: Storage,
    private fb: FirebaseService,
    private globals: Globals,
    private customerService: CustomerService,
    private platform: Platform
  ) {
    translate.setDefaultLang('es');
    this.fb.obsDocument('system', 'goals', false).subscribe(
      res  => {
        this.globals.goals = res;
      }
    );
    this.fb.obsDocument('system', 'customers', false).subscribe(
      res => {
        this.customerService.processCustomers(res);
      }
    );
    this.platform.ready().then(() => {
      //UXCam.optIntoSchematicRecordings();
      //UXCam.startWithKey("g96wma00bl73fgi");
    });
  }
  async ngOnInit() {
    await this.storage.create();
  }
}
